import { actions as parentActions } from '~/sites/shop/store/theme'

const actions = {
  ...parentActions,
  SET_USER_PRICES({ commit, rootGetters, rootState }) {
    if (rootState.user.userData && rootGetters['user/isBusinessUser']) {
      this.$themeSettings.product.price.other.withTax = false
      this.$themeSettings.product.price.primary.withTax = false
      this.$themeSettings.product.price.secondary.show = false
      this.$themeSettings.product.price.totalPrimary.withTax = false
      this.$themeSettings.product.price.summaryTotalPrimary.withTax = false
      this.$themeSettings.product.price.summaryTotalSecondary.label = true
      this.$themeSettings.product.price.summaryTotalSecondary.show = true
      this.$themeSettings.product.price.summaryTotalSecondary.withTax = true
    }
  },
}

export { actions }
