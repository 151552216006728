import { get } from 'lodash'
import {
  actions as parentActions,
  mutations as parentMutations,
  state as parentState,
} from '~/sites/shop/store/catalog'
import gql from 'graphql-tag'
import linkEmbedded from '~/queries/fragments/linkEmbedded'

const actions = {
  ...parentActions,
  async LOAD_SMALL_BANNERS({ commit }) {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          ${linkEmbedded.base}
          query {
            banner_small {
              entities {
                ... on banner_small {
                  description
                  title
                  weight
                  link {
                    ...linkEmbeddedFragmentsBase
                  }
                  main_image {
                    alt
                    path
                    title
                  }
                }
              }
            }
          }
        `,
      })
      commit('SET_SMALL_BANNERS', get(query, 'data.banner_small.entities'))
    } catch (e) {
      console.error(e)
    }
  },
}

const state = () => ({
  ...parentState(),
  smallBanners: null,
})

const mutations = {
  ...parentMutations,
  SET_SMALL_BANNERS(state, banners) {
    state.smallBanners = banners.sort((a, b) => a.weight - b.weight)
  },
}

export { actions, mutations, state }
