import gql from 'graphql-tag'

export const actions = {
  async LOAD_HEADER_CONFIGURATION({ commit }) {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            configuration_lookbook_list {
              description
              image {
                alt
                path
              }
              title
              perex
              page_meta {
                seo_description
                seo_keywords
                seo_title
                meta_nofollow
                meta_noindex
                slug
                share_image
                canonical_slugs {
                  language
                  slug
                }
              }
            }
          }
        `,
      })
      const { configuration_lookbook_list } = query.data || {}
      return configuration_lookbook_list
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_LOOKBOOKS({}, { page, perPage }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          query lookbook($skip: Int!, $limit: Int!) {
            lookbook(skip: $skip, limit: $limit, sort: [{ weight: DESC }]) {
              entities {
                _id
                title
                short_title
                image {
                  title
                  path
                  alt
                }
                file {
                  title
                  path
                  alt
                }
                button_text
              }
              total
            }
          }
        `,
        variables: {
          skip: page * perPage,
          limit: perPage,
        },
      })
      const { entities, total } = result.data.lookbook
      return { entities, total }
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
