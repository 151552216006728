import moment from 'moment'

import 'moment/locale/cs'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import 'moment/locale/sk'

import 'moment-timezone'

moment.locale('cs')

moment.tz.setDefault('Europe/Paris')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
