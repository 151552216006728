import { actions as baseActions } from '~/sites/shop/store/banner'
import gql from 'graphql-tag'

export const actions = {
  ...baseActions,
  async LOAD_HOMEPAGE_BANNERS() {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            banner_wide(filters: { enabled: true }) {
              entities {
                banner_wide_button_text
                banner_wide_text
                banner_wide_text_2
                main_image {
                  path
                  alt
                }
                main_image_translatable {
                  path
                  alt
                }
                main_image_mobile {
                  path
                  alt
                }
                title
                url
                weight
                customer_group_enabled
              }
            }
          }
        `,
      })
      return query.data.banner_wide.entities.sort((a, b) => (b.weight || 0) - (a.weight || 0))
    } catch (e) {
      console.error(e)
    }
  },
}
