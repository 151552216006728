import { actions, mutations, getters as parentGetters, state } from '~/sites/shop/store/user'

const getters = {
  ...parentGetters,
  userGroups: state => {
    return state.userData?.groups || []
  },
  isBusinessUser: (state, getters) => {
    return getters.userGroups.includes('VO') || getters.userGroups.includes('Velkoobchod1')
  },
}

export { actions, getters, mutations, state }
