import jsCookie from 'js-cookie'

export const actions = {
  FETCH_DATA({ commit, getters, state }, { cookie, route }) {
    const { enable: utmCookieEnabled, name: utmCookieName, expiration: utmCookieExpiration } = cookie || {}
    const paramUrlPrefix = 'utm_'
    const { query } = route || {}

    const setParamObject = (obj, prefix) => {
      for (const [name, value] of Object.entries(obj)) {
        if (value && (!prefix || name.startsWith(prefix))) {
          const key = prefix ? name.replace(prefix, '') : name
          commit('SET_PARAM', { key, value })
        }
      }
    }

    // If some utm param present in URL query
    if (Object.keys(query).find(k => k.startsWith(paramUrlPrefix))) {
      setParamObject(query, paramUrlPrefix)
      if (utmCookieEnabled && utmCookieName) {
        const expires = utmCookieExpiration && !isNaN(utmCookieExpiration) ? Number(utmCookieExpiration) : 365
        jsCookie.set(utmCookieName, state.params, {
          expires,
        })
      }
    } else if (utmCookieEnabled && utmCookieName) {
      const utmCookie = jsCookie.get(utmCookieName)
      if (utmCookie) {
        setParamObject(JSON.parse(utmCookie))
      }
    }
  },
}

export const state = () => ({
  params: {
    campaign: false,
    content: false,
    medium: false,
    source: false,
    term: false,
  },
})

export const mutations = {
  SET_PARAM(state, { key, value }) {
    state.params[key] = value
  },
}

export const getters = {
  getUtmParams: state => {
    let output = {}
    for (const [key, value] of Object.entries(state.params)) {
      if (value) {
        output[key] = value
      }
    }
    return Object.keys(output).length ? output : false
  },
}
