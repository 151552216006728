import { mutations, state as parentState } from '~/sites/shop/store/locale'

const state = () => ({
  ...parentState(),
  alternativeDomains: [
    { language: 'cs', domain: 'https://www.represhop.eu' },
    { language: 'sk', domain: 'https://www.represhop.eu/sk' },
    { language: 'en', domain: 'https://www.represhop.eu/en' },
    { language: 'de', domain: 'https://www.represhop.eu/de' },
  ],
  canonicalDomains: [
    { language: 'cs', domain: 'https://www.represhop.eu' },
    { language: 'sk', domain: 'https://www.represhop.eu/sk' },
    { language: 'en', domain: 'https://www.represhop.eu/en' },
    { language: 'de', domain: 'https://www.represhop.eu/de' },
  ],
})
export { mutations, state }
