import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a7ee25f4 = () => interopDefault(import('../sites/represhop/pages/user/activate.vue' /* webpackChunkName: "sites/represhop/pages/user/activate" */))
const _73578ef4 = () => interopDefault(import('../sites/represhop/pages/articleList/index.vue' /* webpackChunkName: "sites/represhop/pages/articleList/index" */))
const _7a7358a1 = () => interopDefault(import('../sites/represhop/pages/index.vue' /* webpackChunkName: "sites/represhop/pages/index" */))
const _9a5feea8 = () => interopDefault(import('../sites/represhop/pages/search/index.vue' /* webpackChunkName: "sites/represhop/pages/search/index" */))
const _5dbd7272 = () => interopDefault(import('../sites/represhop/pages/contact/index.vue' /* webpackChunkName: "sites/represhop/pages/contact/index" */))
const _2da2a6ec = () => interopDefault(import('../sites/represhop/pages/checkout/index.vue' /* webpackChunkName: "sites/represhop/pages/checkout/index" */))
const _4c28fd8a = () => interopDefault(import('../sites/represhop/pages/lookbookList/index.vue' /* webpackChunkName: "sites/represhop/pages/lookbookList/index" */))
const _38625168 = () => interopDefault(import('../sites/represhop/pages/faq/index.vue' /* webpackChunkName: "sites/represhop/pages/faq/index" */))
const _e38ecfb4 = () => interopDefault(import('../sites/represhop/pages/branch/index.vue' /* webpackChunkName: "sites/represhop/pages/branch/index" */))
const _441afbb8 = () => interopDefault(import('../sites/represhop/pages/login.vue' /* webpackChunkName: "sites/represhop/pages/login" */))
const _3e63e224 = () => interopDefault(import('../sites/represhop/pages/register.vue' /* webpackChunkName: "sites/represhop/pages/register" */))
const _6504c343 = () => interopDefault(import('../sites/represhop/pages/passwordReset.vue' /* webpackChunkName: "sites/represhop/pages/passwordReset" */))
const _5179e286 = () => interopDefault(import('../sites/represhop/pages/sitemapconfig.vue' /* webpackChunkName: "sites/represhop/pages/sitemapconfig" */))
const _5d0755bc = () => interopDefault(import('../sites/represhop/pages/maintenance.vue' /* webpackChunkName: "sites/represhop/pages/maintenance" */))
const _1fd43562 = () => interopDefault(import('../sites/represhop/pages/user/index.vue' /* webpackChunkName: "sites/represhop/pages/user/index" */))
const _72cd608e = () => interopDefault(import('../sites/represhop/pages/passwordForgotten.vue' /* webpackChunkName: "sites/represhop/pages/passwordForgotten" */))
const _43f74af0 = () => interopDefault(import('../sites/represhop/pages/auth/proxy.vue' /* webpackChunkName: "sites/represhop/pages/auth/proxy" */))
const _1a98bb70 = () => interopDefault(import('../sites/represhop/pages/articleTag/index.vue' /* webpackChunkName: "sites/represhop/pages/articleTag/index" */))
const _d0827bb4 = () => interopDefault(import('../sites/represhop/pages/checkout/info.vue' /* webpackChunkName: "sites/represhop/pages/checkout/info" */))
const _619eabea = () => interopDefault(import('../sites/represhop/pages/checkout/finished.vue' /* webpackChunkName: "sites/represhop/pages/checkout/finished" */))
const _10e1576a = () => interopDefault(import('../sites/represhop/pages/checkout/methods.vue' /* webpackChunkName: "sites/represhop/pages/checkout/methods" */))
const _1ed064a4 = () => interopDefault(import('../sites/represhop/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/represhop/pages/checkout/importOrder/index" */))
const _154ea7e6 = () => interopDefault(import('../sites/represhop/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/represhop/pages/user/shoppingLists/index" */))
const _2e8fd13b = () => interopDefault(import('../sites/represhop/pages/user/orders/index.vue' /* webpackChunkName: "sites/represhop/pages/user/orders/index" */))
const _1318bc36 = () => interopDefault(import('../sites/represhop/pages/user/settings.vue' /* webpackChunkName: "sites/represhop/pages/user/settings" */))
const _4cf4fb91 = () => interopDefault(import('../sites/represhop/pages/user/dashboard.vue' /* webpackChunkName: "sites/represhop/pages/user/dashboard" */))
const _43b21922 = () => interopDefault(import('../sites/represhop/pages/user/addressList.vue' /* webpackChunkName: "sites/represhop/pages/user/addressList" */))
const _150bbeae = () => interopDefault(import('../sites/represhop/pages/user/password.vue' /* webpackChunkName: "sites/represhop/pages/user/password" */))
const _6aee03a6 = () => interopDefault(import('../sites/represhop/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/represhop/pages/checkout/payment/callback" */))
const _674c4db5 = () => interopDefault(import('../sites/represhop/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/represhop/pages/user/shoppingLists/_id" */))
const _6941917e = () => interopDefault(import('../sites/represhop/pages/articleList/_slug/index.vue' /* webpackChunkName: "sites/represhop/pages/articleList/_slug/index" */))
const _1e05fe00 = () => interopDefault(import('../sites/represhop/pages/articleTag/_slug.vue' /* webpackChunkName: "sites/represhop/pages/articleTag/_slug" */))
const _14c6e663 = () => interopDefault(import('../sites/represhop/pages/user/orders/_id.vue' /* webpackChunkName: "sites/represhop/pages/user/orders/_id" */))
const _18343a3e = () => interopDefault(import('../sites/represhop/pages/articleList/_slug/_child.vue' /* webpackChunkName: "sites/represhop/pages/articleList/_slug/_child" */))
const _1228e387 = () => interopDefault(import('../sites/represhop/pages/author/_slug.vue' /* webpackChunkName: "sites/represhop/pages/author/_slug" */))
const _2ee3e680 = () => interopDefault(import('../sites/represhop/pages/article/_slug.vue' /* webpackChunkName: "sites/represhop/pages/article/_slug" */))
const _e6fc1244 = () => interopDefault(import('../sites/represhop/pages/branch/_slug.vue' /* webpackChunkName: "sites/represhop/pages/branch/_slug" */))
const _10cff98e = () => interopDefault(import('../sites/represhop/pages/product/_slug.vue' /* webpackChunkName: "sites/represhop/pages/product/_slug" */))
const _3f9a15cb = () => interopDefault(import('../sites/represhop/pages/page/_slug.vue' /* webpackChunkName: "sites/represhop/pages/page/_slug" */))
const _7bfd55a4 = () => interopDefault(import('../sites/represhop/pages/_.vue' /* webpackChunkName: "sites/represhop/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _a7ee25f4,
    name: "user-activate___cs"
  }, {
    path: "/blog",
    component: _73578ef4,
    name: "articleList___cs"
  }, {
    path: "/de",
    component: _7a7358a1,
    name: "index___de"
  }, {
    path: "/en",
    component: _7a7358a1,
    name: "index___en"
  }, {
    path: "/hledat",
    component: _9a5feea8,
    name: "search___cs"
  }, {
    path: "/kontakt",
    component: _5dbd7272,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _2da2a6ec,
    name: "checkout___cs"
  }, {
    path: "/lookbook",
    component: _4c28fd8a,
    name: "lookbookList___cs"
  }, {
    path: "/nejcastejsi-dotazy",
    component: _38625168,
    name: "faq___cs"
  }, {
    path: "/pobocky",
    component: _e38ecfb4,
    name: "branch___cs"
  }, {
    path: "/prihlasit",
    component: _441afbb8,
    name: "login___cs"
  }, {
    path: "/registrovat",
    component: _3e63e224,
    name: "register___cs"
  }, {
    path: "/resetovat-heslo",
    component: _6504c343,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _5179e286,
    name: "sitemapconfig___cs"
  }, {
    path: "/sk",
    component: _7a7358a1,
    name: "index___sk"
  }, {
    path: "/udrzba",
    component: _5d0755bc,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _1fd43562,
    name: "user___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _72cd608e,
    name: "passwordForgotten___cs"
  }, {
    path: "/auth/proxy",
    component: _43f74af0,
    name: "auth-proxy___cs"
  }, {
    path: "/blog/tema",
    component: _1a98bb70,
    name: "articleTag___cs"
  }, {
    path: "/de/aktivieren",
    component: _a7ee25f4,
    name: "user-activate___de"
  }, {
    path: "/de/blog",
    component: _73578ef4,
    name: "articleList___de"
  }, {
    path: "/de/einloggen",
    component: _441afbb8,
    name: "login___de"
  }, {
    path: "/de/faq",
    component: _38625168,
    name: "faq___de"
  }, {
    path: "/de/instandhaltung",
    component: _5d0755bc,
    name: "maintenance___de"
  }, {
    path: "/de/jetzt-registrieren",
    component: _3e63e224,
    name: "register___de"
  }, {
    path: "/de/kontakte",
    component: _5dbd7272,
    name: "contact___de"
  }, {
    path: "/de/lookbook",
    component: _4c28fd8a,
    name: "lookbookList___de"
  }, {
    path: "/de/mein-konto",
    component: _1fd43562,
    name: "user___de"
  }, {
    path: "/de/niederlassungen",
    component: _e38ecfb4,
    name: "branch___de"
  }, {
    path: "/de/passwort-vergessen",
    component: _72cd608e,
    name: "passwordForgotten___de"
  }, {
    path: "/de/passwort-zurucksetzen",
    component: _6504c343,
    name: "passwordReset___de"
  }, {
    path: "/de/sitemapconfig",
    component: _5179e286,
    name: "sitemapconfig___de"
  }, {
    path: "/de/suche",
    component: _9a5feea8,
    name: "search___de"
  }, {
    path: "/de/warenkorb",
    component: _2da2a6ec,
    name: "checkout___de"
  }, {
    path: "/en/activate",
    component: _a7ee25f4,
    name: "user-activate___en"
  }, {
    path: "/en/blog",
    component: _73578ef4,
    name: "articleList___en"
  }, {
    path: "/en/branches",
    component: _e38ecfb4,
    name: "branch___en"
  }, {
    path: "/en/cart",
    component: _2da2a6ec,
    name: "checkout___en"
  }, {
    path: "/en/contact",
    component: _5dbd7272,
    name: "contact___en"
  }, {
    path: "/en/faq",
    component: _38625168,
    name: "faq___en"
  }, {
    path: "/en/forgotten-password",
    component: _72cd608e,
    name: "passwordForgotten___en"
  }, {
    path: "/en/login",
    component: _441afbb8,
    name: "login___en"
  }, {
    path: "/en/lookbook",
    component: _4c28fd8a,
    name: "lookbookList___en"
  }, {
    path: "/en/maintenance",
    component: _5d0755bc,
    name: "maintenance___en"
  }, {
    path: "/en/register",
    component: _3e63e224,
    name: "register___en"
  }, {
    path: "/en/reset-password",
    component: _6504c343,
    name: "passwordReset___en"
  }, {
    path: "/en/search",
    component: _9a5feea8,
    name: "search___en"
  }, {
    path: "/en/sitemapconfig",
    component: _5179e286,
    name: "sitemapconfig___en"
  }, {
    path: "/en/user",
    component: _1fd43562,
    name: "user___en"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _d0827bb4,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _619eabea,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _10e1576a,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _1ed064a4,
    name: "checkout-importOrder___cs"
  }, {
    path: "/sk/aktivovat",
    component: _a7ee25f4,
    name: "user-activate___sk"
  }, {
    path: "/sk/blog",
    component: _73578ef4,
    name: "articleList___sk"
  }, {
    path: "/sk/checkout",
    component: _2da2a6ec,
    name: "checkout___sk"
  }, {
    path: "/sk/hladat",
    component: _9a5feea8,
    name: "search___sk"
  }, {
    path: "/sk/kontakt",
    component: _5dbd7272,
    name: "contact___sk"
  }, {
    path: "/sk/lookbook",
    component: _4c28fd8a,
    name: "lookbookList___sk"
  }, {
    path: "/sk/najcastejsie-otazky",
    component: _38625168,
    name: "faq___sk"
  }, {
    path: "/sk/pobocky",
    component: _e38ecfb4,
    name: "branch___sk"
  }, {
    path: "/sk/prihlasit",
    component: _441afbb8,
    name: "login___sk"
  }, {
    path: "/sk/registrovat",
    component: _3e63e224,
    name: "register___sk"
  }, {
    path: "/sk/resetovat-heslo",
    component: _6504c343,
    name: "passwordReset___sk"
  }, {
    path: "/sk/sitemapconfig",
    component: _5179e286,
    name: "sitemapconfig___sk"
  }, {
    path: "/sk/udrzba",
    component: _5d0755bc,
    name: "maintenance___sk"
  }, {
    path: "/sk/uzivatel",
    component: _1fd43562,
    name: "user___sk"
  }, {
    path: "/sk/zabudnute-heslo",
    component: _72cd608e,
    name: "passwordForgotten___sk"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _154ea7e6,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _2e8fd13b,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _1318bc36,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _4cf4fb91,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/seznam-adres",
    component: _43b21922,
    name: "user-addressList___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _150bbeae,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _6aee03a6,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/de/auth/proxy",
    component: _43f74af0,
    name: "auth-proxy___de"
  }, {
    path: "/de/blog/thema",
    component: _1a98bb70,
    name: "articleTag___de"
  }, {
    path: "/de/mein-konto/addressliste",
    component: _43b21922,
    name: "user-addressList___de"
  }, {
    path: "/de/mein-konto/bearbeiten",
    component: _1318bc36,
    name: "user-settings___de"
  }, {
    path: "/de/mein-konto/bestellungen",
    component: _2e8fd13b,
    name: "user-orders___de"
  }, {
    path: "/de/mein-konto/einkaufslisten",
    component: _154ea7e6,
    name: "user-shoppingLists___de"
  }, {
    path: "/de/mein-konto/einkaufslisten",
    component: _674c4db5,
    name: "user-shoppingLists-id___de"
  }, {
    path: "/de/mein-konto/passwort-andern",
    component: _150bbeae,
    name: "user-password___de"
  }, {
    path: "/de/mein-konto/uberblick",
    component: _4cf4fb91,
    name: "user-dashboard___de"
  }, {
    path: "/de/warenkorb/fertig",
    component: _619eabea,
    name: "checkout-finished___de"
  }, {
    path: "/de/warenkorb/importauftrag",
    component: _1ed064a4,
    name: "checkout-importOrder___de"
  }, {
    path: "/de/warenkorb/lieferdaten",
    component: _d0827bb4,
    name: "checkout-info___de"
  }, {
    path: "/de/warenkorb/versand-und-bezahlung",
    component: _10e1576a,
    name: "checkout-methods___de"
  }, {
    path: "/en/auth/proxy",
    component: _43f74af0,
    name: "auth-proxy___en"
  }, {
    path: "/en/blog/theme",
    component: _1a98bb70,
    name: "articleTag___en"
  }, {
    path: "/en/cart/delivery-details",
    component: _d0827bb4,
    name: "checkout-info___en"
  }, {
    path: "/en/cart/finish",
    component: _619eabea,
    name: "checkout-finished___en"
  }, {
    path: "/en/cart/import-order",
    component: _1ed064a4,
    name: "checkout-importOrder___en"
  }, {
    path: "/en/cart/shipping-and-payment",
    component: _10e1576a,
    name: "checkout-methods___en"
  }, {
    path: "/en/user/address-list",
    component: _43b21922,
    name: "user-addressList___en"
  }, {
    path: "/en/user/change-password",
    component: _150bbeae,
    name: "user-password___en"
  }, {
    path: "/en/user/orders",
    component: _2e8fd13b,
    name: "user-orders___en"
  }, {
    path: "/en/user/overview",
    component: _4cf4fb91,
    name: "user-dashboard___en"
  }, {
    path: "/en/user/settings",
    component: _1318bc36,
    name: "user-settings___en"
  }, {
    path: "/en/user/shopping-lists",
    component: _154ea7e6,
    name: "user-shoppingLists___en"
  }, {
    path: "/en/user/shopping-lists",
    component: _674c4db5,
    name: "user-shoppingLists-id___en"
  }, {
    path: "/sk/auth/proxy",
    component: _43f74af0,
    name: "auth-proxy___sk"
  }, {
    path: "/sk/blog/tema",
    component: _1a98bb70,
    name: "articleTag___sk"
  }, {
    path: "/sk/kosik/dodacie-udaje",
    component: _d0827bb4,
    name: "checkout-info___sk"
  }, {
    path: "/sk/kosik/dokoncenie",
    component: _619eabea,
    name: "checkout-finished___sk"
  }, {
    path: "/sk/kosik/doprava-a-platba",
    component: _10e1576a,
    name: "checkout-methods___sk"
  }, {
    path: "/sk/kosik/import-objednavky",
    component: _1ed064a4,
    name: "checkout-importOrder___sk"
  }, {
    path: "/sk/uzivatel/nakupne-zoznamy",
    component: _154ea7e6,
    name: "user-shoppingLists___sk"
  }, {
    path: "/sk/uzivatel/objednavky",
    component: _2e8fd13b,
    name: "user-orders___sk"
  }, {
    path: "/sk/uzivatel/osobni-udaje",
    component: _1318bc36,
    name: "user-settings___sk"
  }, {
    path: "/sk/uzivatel/prehled",
    component: _4cf4fb91,
    name: "user-dashboard___sk"
  }, {
    path: "/sk/uzivatel/zmena-hesla",
    component: _150bbeae,
    name: "user-password___sk"
  }, {
    path: "/sk/uzivatel/zoznam-adries",
    component: _43b21922,
    name: "user-addressList___sk"
  }, {
    path: "/de/checkout/payment/callback",
    component: _6aee03a6,
    name: "checkout-payment-callback___de"
  }, {
    path: "/en/checkout/payment/callback",
    component: _6aee03a6,
    name: "checkout-payment-callback___en"
  }, {
    path: "/sk/checkout/payment/callback",
    component: _6aee03a6,
    name: "checkout-payment-callback___sk"
  }, {
    path: "/",
    component: _7a7358a1,
    name: "index___cs"
  }, {
    path: "/de/blog/kategorie/:slug",
    component: _6941917e,
    name: "articleList-slug___de"
  }, {
    path: "/de/blog/thema/:slug",
    component: _1e05fe00,
    name: "articleTag-slug___de"
  }, {
    path: "/de/mein-konto/bestellungen/:id",
    component: _14c6e663,
    name: "user-orders-id___de"
  }, {
    path: "/en/blog/category/:slug",
    component: _6941917e,
    name: "articleList-slug___en"
  }, {
    path: "/en/blog/theme/:slug",
    component: _1e05fe00,
    name: "articleTag-slug___en"
  }, {
    path: "/en/user/orders/:id",
    component: _14c6e663,
    name: "user-orders-id___en"
  }, {
    path: "/sk/blog/kategorie/:slug",
    component: _6941917e,
    name: "articleList-slug___sk"
  }, {
    path: "/sk/blog/tema/:slug",
    component: _1e05fe00,
    name: "articleTag-slug___sk"
  }, {
    path: "/sk/uzivatel/nakupne-zoznamy/:id",
    component: _674c4db5,
    name: "user-shoppingLists-id___sk"
  }, {
    path: "/sk/uzivatel/objednavky/:id",
    component: _14c6e663,
    name: "user-orders-id___sk"
  }, {
    path: "/de/blog/kategorie/:slug/:child",
    component: _18343a3e,
    name: "articleList-slug-child___de"
  }, {
    path: "/en/blog/category/:slug/:child",
    component: _18343a3e,
    name: "articleList-slug-child___en"
  }, {
    path: "/sk/blog/kategorie/:slug/:child",
    component: _18343a3e,
    name: "articleList-slug-child___sk"
  }, {
    path: "/blog/kategorie/:slug",
    component: _6941917e,
    name: "articleList-slug___cs"
  }, {
    path: "/blog/tema/:slug",
    component: _1e05fe00,
    name: "articleTag-slug___cs"
  }, {
    path: "/de/autoren/:slug",
    component: _1228e387,
    name: "author-slug___de"
  }, {
    path: "/de/blog/:slug",
    component: _2ee3e680,
    name: "article-slug___de"
  }, {
    path: "/de/niederlassung/:slug",
    component: _e6fc1244,
    name: "branch-slug___de"
  }, {
    path: "/de/produkt/:slug",
    component: _10cff98e,
    name: "product-slug___de"
  }, {
    path: "/de/seite/:slug",
    component: _3f9a15cb,
    name: "page-slug___de"
  }, {
    path: "/en/authors/:slug",
    component: _1228e387,
    name: "author-slug___en"
  }, {
    path: "/en/blog/:slug",
    component: _2ee3e680,
    name: "article-slug___en"
  }, {
    path: "/en/branches/:slug",
    component: _e6fc1244,
    name: "branch-slug___en"
  }, {
    path: "/en/page/:slug",
    component: _3f9a15cb,
    name: "page-slug___en"
  }, {
    path: "/en/product/:slug",
    component: _10cff98e,
    name: "product-slug___en"
  }, {
    path: "/sk/autori/:slug",
    component: _1228e387,
    name: "author-slug___sk"
  }, {
    path: "/sk/blog/:slug",
    component: _2ee3e680,
    name: "article-slug___sk"
  }, {
    path: "/sk/pobocky/:slug",
    component: _e6fc1244,
    name: "branch-slug___sk"
  }, {
    path: "/sk/produkt/:slug",
    component: _10cff98e,
    name: "product-slug___sk"
  }, {
    path: "/sk/stranka/:slug",
    component: _3f9a15cb,
    name: "page-slug___sk"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _674c4db5,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _14c6e663,
    name: "user-orders-id___cs"
  }, {
    path: "/blog/kategorie/:slug/:child",
    component: _18343a3e,
    name: "articleList-slug-child___cs"
  }, {
    path: "/autori/:slug",
    component: _1228e387,
    name: "author-slug___cs"
  }, {
    path: "/blog/:slug",
    component: _2ee3e680,
    name: "article-slug___cs"
  }, {
    path: "/pobocky/:slug",
    component: _e6fc1244,
    name: "branch-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _10cff98e,
    name: "product-slug___cs"
  }, {
    path: "/stranka/:slug",
    component: _3f9a15cb,
    name: "page-slug___cs"
  }, {
    path: "/sk/*",
    component: _7bfd55a4,
    name: "all___sk"
  }, {
    path: "/en/*",
    component: _7bfd55a4,
    name: "all___en"
  }, {
    path: "/de/*",
    component: _7bfd55a4,
    name: "all___de"
  }, {
    path: "/*",
    component: _7bfd55a4,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
