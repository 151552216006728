import { merge } from 'lodash'
import contentSettings from '~/sites/shop/content.settings'

const overloadSettings = {
  ...contentSettings,
  colorParameterId: '5caf33f2dc476b001e8ecb95',
  lightwidgetInstagramId: 'f75cce58654f5588bbf191bf0a12ebb0',
  functions: {
    ...contentSettings.functions,
    products_user_reviews: true,
    shoppingLists: true,
    wishList: true,
  },
  infoBoxes: false,
  utm: {
    enable: true,
  },
}

export default merge(contentSettings, overloadSettings)
