import { get } from 'lodash'
import { getters, mutations, actions as parentActions, state as parentState } from '~/sites/shop/store/globals'

const actions = {
  ...parentActions,
  CUSTOM_CONFIGURATION_CMS_QUERY() {
    return `configuration_infinite_text {
      text
    }`
  },
  CUSTOM_CONFIGURATION_CMS_FETCH({ commit }, { result }) {
    const data = get(result, 'cms.data')

    commit('SET_FIELD', {
      field: 'infiniteText',
      value: get(data, 'configuration_infinite_text.text'),
    })
  },
}

const state = () => ({
  ...parentState(),
  infiniteText: '',
})

export { actions, state, mutations, getters }
