import { merge } from 'lodash'
import themeSettings from '~/themes/base/settings'

const overloadSettings = {
  catalog: {
    defaultSortType: 'LatestSort',
    cannableCategory: 31,
    cannableCodeExcluded: 'W-BOX',
  },
  components: {
    ArticleBox: {
      articleInfo: {
        enable: true,
        date: {
          enable: true,
          icon: 'represhop/calendar',
        },
        category: {
          enable: false,
        },
        author: {
          enable: false,
        },
      },
      image: {
        height: 400,
        width: 850,
      },
      linkReadMore: {
        button: 'outline-primary border',
        enable: false,
      },
      showIcons: true,
    },
    ArticleList: {
      perLine: {
        xs: 1,
        lg: 1,
      },
      perPage: 5,
    },
    BranchBox: {
      showExtraOpeningHours: false,
    },
    BranchSelectorPPL: {
      stripBranchCode: false, // Remove KM from branch code
    },
    Carousel: {
      leftIcon: 'represhop/arrow-left',
      rightIcon: 'represhop/arrow-right',
      showPartOfNextProduct: false,
    },
    CatalogSorting: {
      options: ['LatestSort', 'MostSoldSort', 'PriceSortASC', 'PriceSortDESC'], // See available options in CatalogSorting.js
    },
    CartInfo: {
      mainIcon: 'represhop/cart',
      showPrice: false,
    },
    CartInfoDropDownContent: {
      showDeliveryDate: false,
    },
    CatalogFilters: {
      showInStockFilter: false,
      showPriceFilter: false,
      showTagFilter: false,
    },
    CheckoutRecapBox: {
      showTotalSecondaryPrice: true,
    },
    ContentHeader: {
      image: {
        crop: true,
        height: 250,
        required: false,
        width: 1210,
        upscale: true,
      },
      showDescription: true,
      showPerex: true,
    },
    CurrencySwitcher: {
      displayNameType: 'code',
      icon: 'represhop/down-chevron',
    },
    CheckoutItemLine: {
      showDeliveryDate: false,
    },
    FeaturedArticles: {
      fullViewportWidthWrapper: true,
      linkAllArticles: {
        enable: true,
        button: 'outline-primary border',
      },
    },
    FittingGuideModal: {
      modalWindowMaxWidth: 'xl',
    },
    FooterBottom: {
      paymentMethods: false,
      showContact: false,
    },
    FooterContact: {
      email: {
        icon: false,
      },
      phone: {
        icon: false,
      },
      showSocialNetworkLinks: true,
    },
    FooterMain: {
      showContact: true,
      showSocialNetworkLinks: false,
    },
    HeaderMain: {
      enableStickyOnMobile: true,
      loginLink: {
        enable: true,
        type: 'dropdown',
        showLabel: false,
      },
      LogoutButton: {
        button: 'primary', // 'transparent', utility class ['primary'/'secondary'...] or leave empty to display as link
        icon: null,
      },
      logo: {
        position: 'center',
      },
      showHeaderTopMenu: true,
      showHeaderMainHook: false,
      showUserMenu: true,
      showCurrencySwitcher: true,
      showLanguageSwitcher: true,
      showSearchInput: false,
    },
    InstagramWidget: {
      headingClass: 'Title--gama text-center',
    },
    LanguageBanner: {
      enable: true,
    },
    LanguageSwitcher: {
      dropdownTriggerType: ['click', 'focus'],
      shortSelectedName: true,
      selectedIcon: 'represhop/down-chevron',
      useAlternativeLinks: true,
      useCanonicalLinks: true,
      useCanonicalLinksLocally: true,
      useHomepageLinks: true,
    },
    MainMenu: {
      enableDropdown: true,
      fullViewportWidthWrapper: true,
      showDropdownIndicator: false,
    },
    MainMenuDropdownContent: {
      direction: 'row',
      content: {
        icon: '', // Visible for type `link` only
        type: 'link', // SubCategoryBox, link
      },
      showHeading: false,
      subcategoriesCountPerLine: {
        sm: 1,
        md: 3,
        lg: 4,
        xl: 4,
      },
      subCategoryBoxStyle: '', // 'outlined'
    },
    Newsletter: {
      alignToCenter: false,
      icon: 'represhop/newsletter',
    },
    SubCategoryBox: {
      image: {
        crop: true,
        upscale: true,
      },
    },
    PageArticleDetail: {
      articleInfo: {
        author: {
          showLink: true,
          icon: 'represhop/author',
        },
        category: {
          icon: 'represhop/folder',
        },
        date: {
          icon: 'represhop/calendar',
        },
      },
      backToArticles: {
        enable: true,
        button: 'sm-block border',
      },
      header: {
        enable: true,
      },
      latestArticles: {
        count: 4,
      },
    },
    PageArticleList: {
      header: {
        enable: true,
      },
    },
    PageArticleListCategory: {
      header: {
        enable: true,
      },
    },
    PageCatalog: {
      image: {
        required: true,
      },
      subCategories: {
        enable: true,
        useContainerComponent: true,
      },
      showCategoryMenuInSidebar: false,
      showContactBox: false,
    },
    PageHomepage: {
      featuredArticles: {
        enable: true,
        count: 3,
      },
      mostSoldProducts: {
        enable: true,
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
          xxl: 5,
        },
      },
      newestProducts: {
        enable: true,
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
          xxl: 5,
        },
      },
      showHomepageTextBlock: true,
      showSlider: true,
      topCategories: {
        enable: true,
        fullViewportWidthWrapper: true,
      },
    },
    PageLogin: {
      registrationInfoButtonType: 'primary',
    },
    PageLookbookList: {
      header: {
        enable: true,
      },
    },
    PageProductDetail: {
      countOfVariantsToShow: 6,
      showAvailabilityInWarehouses: false,
      showCategoryMenuInSidebar: false,
      showColorVariants: true,
      showContactBoxInSidebar: false,
      showDeliveryOptions: false,
      accessoryProducts: {
        perLine: {
          xs: 1,
          md: 2,
          lg: 4,
          xl: 5,
        },
      },
      recommendedProducts: {
        perLine: {
          xs: 1,
          md: 2,
          lg: 4,
          xl: 5,
        },
      },
      alternativeProducts: {
        enable: false,
      },
      reviews: {
        enable: true,
      },
    },
    PageUserOrderDetail: {
      filesDownload: {
        csv: {
          enable: true,
          onlyForBusiness: true, // Allow only for Business users
        },
        images: {
          enable: true,
          onlyForBusiness: true, // Allow only for Business users
        },
      },
    },
    ProductBox: {
      addToCartButton: 'primary',
      imagePortraitWidth: 265,
      imagePortraitHeight: 400,
      imageWidth: 256,
      imageHeight: 248,
      showAvailability: false,
      showCategory: false,
      showDescription: false,
      showB2CPriceForB2BPartner: true,
      showAddToCartButton: false,
    },
    ProductBoxVariants: {
      enableBatchCart: true,
      enableInactiveVariants: false,
    },
    ProductTeaser: {
      image: {
        width: 256,
        height: 248,
      },
      showAvailability: false,
    },
    ProductsVisited: {
      enable: true,
    },
    Sticky: {
      alwaysVisible: true,
      enable: true,
    },
    Slider: {
      cmsTextContent: {
        enable: true,
      },
      image: {
        width: 1920,
        height: 700,
      },
      imageMobile: {
        width: 1536,
        height: 1920,
      },
      navigation: {
        button: 'btn-outline-primary',
        enable: true,
        nextIcon: 'represhop/arrow-right',
        prevIcon: 'represhop/arrow-left',
      },
      showCirclePagination: true,
      showTabs: false,
      showTimerIndicator: false,
    },
    SocialNetworkLinks: {
      showLabel: false,
      showNetworkName: false,
    },
    TopBanner: {
      alignContent: 'center',
    },
    TopCategories: {
      innerContainerClass: '',
      perLine: {
        sm: 1,
        md: 3,
      },
    },
    TopCategory: {
      headingClass: 'Title--alpha',
      image: {
        crop: true,
        width: 640,
        height: 590,
      },
    },
  },
  global: {
    userLinks: {
      register: {
        enable: true,
      },
    },
  },
  layouts: {
    LayoutBase: {
      headerStickyAt: 'vh/5',
      headerStickyEnable: true,
      showHeaderTop: false,
    },
  },
  product: {
    price: {
      summaryTotalSecondary: {
        show: false,
      },
    },
    shipperListHiddenShipperCodes: [], // Hides selected shippers from the list, used in combination with warehouses
    tagFreeShippers: 'green',
    tagHasGift: 'purple',
    tagNotSaleableColor: 'gray',
    tagQuantityDiscountColor: 'red',
  },
}

export default merge(themeSettings, overloadSettings)
