import { actions as parentActions } from '~/sites/shop/store/homepage'
import contentBlocksQuery from '~/app/contentBlocksQuery'
import gql from 'graphql-tag'
import linkEmbedded from '~/queries/fragments/linkEmbedded'
import processContentBuilder from '@/app/processContentBuilder'

const actions = {
  ...parentActions,
  async LOAD_HOMEPAGE_CONFIGURATION({ dispatch }) {
    try {
      const client = this.app.apolloProvider.defaultClient
      const response = await client.query({
        query: gql`
            ${linkEmbedded.base}
          query {
            configuration_hp_content {
              content
              content_blocks {
                ${contentBlocksQuery()}
              }
            }
            configuration_our_story {
              title
              perex
              text
              image {
                alt
                path
                title
              }
              button_link {
                ...linkEmbeddedFragmentsBase
              }
            }
          }
        `,
      })
      const { configuration_hp_content, configuration_our_story } = response.data
      const data = configuration_hp_content
      if (data.content) {
        data.content = await processContentBuilder(data.content, dispatch)
      }
      return { ...data, ourStory: configuration_our_story }
    } catch (e) {
      console.error(e)
    }
  },
}

export { actions }
