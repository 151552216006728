/*
More information about swiper:
* https://www.npmjs.com/package/vue-awesome-swiper
* !!! Use swiper v5 !!!
* API doc.: https://github.com/nolimits4web/swiper/blob/Swiper5/API.md
* There is a bug with infinite loop and autoplay in v6
* Official API doc. (v6+):https://swiperjs.com/vue
*/
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style
import '~/node_modules/swiper/css/swiper.min.css'

Vue.use(VueAwesomeSwiper)
