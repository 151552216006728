import { get } from 'lodash'
import {
  actions as parentActions,
  mutations as parentMutations,
  state as parentState,
} from '~/sites/shop/store/category'
import categoryFragments from '~/queries/fragments/category'
import gql from 'graphql-tag'
import processContentBuilder from '~/app/processContentBuilder'

const actions = {
  ...parentActions,
  async LOAD_CATEGORY_CMS({ dispatch, state }, { storeId }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${categoryFragments.all}
          query category($storeId: Float!) {
            category(filters: { store_id: [$storeId] }) {
              entities {
                ...categoryFragmentAll
              }
            }
          }
        `,
        variables: {
          storeId,
        },
      })
      const data = get(result, 'data.category.entities[0]')

      if (data) {
        data.content = await processContentBuilder(data.content, dispatch)
        return data
      }
    } catch (e) {
      console.error(e)
    }
  },
}

const state = () => ({
  ...parentState(),
})

const mutations = {
  ...parentMutations,
}

export { actions, mutations, state }
