<script>
import BusinessUserMixin from '~/sites/shop/mixins/BusinessUserMixin.vue'

export default {
  extends: BusinessUserMixin,
  computed: {
    isBusinessUser() {
      return process.client ? this.$store.getters['user/isBusinessUser'] : false
    },
  },
}
</script>
