import { hydrateWhenVisible } from 'vue-lazy-hydration'
import FooterUsp from '@theme/components/shop/FooterUsp'
import LayoutBase from '~/themes/base/components/shop/LayoutBase'

export default {
  extends: LayoutBase,
  components: {
    FooterUsp: hydrateWhenVisible(FooterUsp),
  },
}
