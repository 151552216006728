<script>
import NewsletterFormLazy from '~/themes/base/components/shop/NewsletterForm/NewsletterFormLazy.vue'
export default {
  extends: NewsletterFormLazy,
  data() {
    return {
      componentFactory: () => import('./NewsletterForm.vue'),
    }
  },
}
</script>
