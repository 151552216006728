import { getters, mutations, actions as parentActions, state as parentState } from '~/sites/shop/store/cart'
import { hookDefinitions, hookDispatch } from '~/hooks'
import { sumBy } from 'lodash'

const state = () => {
  const parentStateObject = parentState()
  parentStateObject.input.canCount = -1
  return parentStateObject
}

const actions = {
  ...parentActions,
  async PLACE_ORDER(actionContext) {
    const { commit, state, dispatch, rootState, rootGetters, getters } = actionContext
    try {
      const name = this.$themeSettings.global.userFullName
        ? state.input.fullName
        : state.input.firstName + ' ' + state.input.lastName
      const shippingName = this.$themeSettings.global.userFullName
        ? state.input.shippingFullName
        : state.input.shippingFirstName + ' ' + state.input.shippingLastName

      const getDefaultAddress = key => {
        const searchKey = key !== 'country_code' ? key : 'countryCode'
        return state.input.shipperBranch
          ? state.input.shipperBranch.address[searchKey]
          : rootState.globals.contact.address[searchKey]
      }
      const setShipperAddress = addressData => {
        for (const val of ['street', 'city', 'postcode', 'country_code']) {
          addressData[val] = getDefaultAddress(val)
        }
      }
      const isRegistration = getters.isRegistration
      let note = state.input.note
      const canCount = parseInt(state.input.canCount)
      const maxCanCount = sumBy(state.cartData.items, cartItem => {
        let value = 0

        if (
          cartItem.product &&
          !cartItem.product.catalogCode.includes(this.$themeSettings.catalog.cannableCodeExcluded) &&
          cartItem.product.categoryIds &&
          cartItem.product.categoryIds.includes(this.$themeSettings.catalog.cannableCategory)
        ) {
          value = cartItem.amount
        }

        return value
      })

      // If user selects number of cans lower than possible maximum, add that request to note
      if (canCount < maxCanCount && maxCanCount > 0) {
        note = `Zákazník si přeje ${Math.min(canCount, maxCanCount)}x plechovku na trenky. | ${state.input.note}`
      }

      const data = {
        name,
        firstName: state.input.firstName,
        lastName: state.input.lastName,
        email: state.input.email,
        phone: state.input.phone,
        billing_address: {
          street: state.input.billingHouseNumber
            ? `${state.input.billingStreet} ${state.input.billingHouseNumber}`
            : state.input.billingStreet,
          city: state.input.billingCity,
          postcode: state.input.billingPostcode,
          country_code: state.input.billingCountry,
        },
        business: state.input.isBusiness
          ? {
              business_name: state.input.businessName,
              TIN: state.input.tin,
              VATIN: state.input.vatin,
            }
          : null,
        shipping: state.input.shipToShippingAddress
          ? {
              name: shippingName.replace(' ', '') !== '' ? shippingName : name,
              address: {
                street: state.input.houseNumber
                  ? `${state.input.street} ${state.input.houseNumber}`
                  : state.input.street,
                city: state.input.city,
                postcode: state.input.postcode,
                country_code: state.input.country,
              },
            }
          : {
              name: shippingName.replace(' ', '') !== '' ? shippingName : name,
              address: {
                street: state.input.billingHouseNumber
                  ? `${state.input.billingStreet} ${state.input.billingHouseNumber}`
                  : state.input.billingStreet,
                city: state.input.billingCity,
                postcode: state.input.billingPostcode,
                country_code: state.input.billingCountry,
              },
            },
        custom: state.input.custom,
        comment: note,
        shipper_comment: state.input.note_for_shipper,
        payment_id: state.input.payment ? state.input.payment.id : null,
        shipper_id: state.input.shipper ? state.input.shipper.id : null,
        branch_id: state.input.shipperBranch ? state.input.shipperBranch.id : null,
        terms: true,
        cardRequest: state.input.cardRequest,
        order_gifts: state.selectedGifts.map(g => g.id),
        newsletter: state.input.newsletter,
        heureka_allow: state.input.complianceHeureka,
        zbozicz_allow: state.input.complianceZbozi,
        magazine: state.input.complianceMagazine,
        phone_notification: state.input.complianceSmsMarketing,
        delivery_time_id: state.input.deliveryTimeSlot ? state.input.deliveryTimeSlot.id : null,
        ...(isRegistration
          ? {
              password: state.input.password,
              password_confirmation: state.input.passwordConfirmation,
              registration: true,
            }
          : {}),
      }
      if (state.input.shippingBusinessName && state.input.shippingBusinessName.replace(/\s/g, '').length > 0) {
        if (
          state.input.isBusiness
            ? !!this.$themeSettings.components.PageCheckoutInfo.input.shippingBusinessName.enableWithBusiness
            : true
        ) {
          data.shipping.business_name = state.input.shippingBusinessName
        }
      }
      if (
        state.input.shipToShippingAddress &&
        state.input.shippingPhone &&
        state.input.shippingPhone.replace(/\s/g, '').length > 0
      ) {
        data.shipping.phone = state.input.shippingPhone
      }
      if (state.input.formalTitle) {
        data.custom.formalTitle = state.input.formalTitle
      }
      if (state.input.vatinsvk) {
        data.custom.ICDPH = state.input.vatinsvk
      }
      if (state.input.dateOfBirth) {
        data.custom.dateOfBirth = state.input.dateOfBirth
      }
      if (state.input.companyName) {
        data.custom.companyName = state.input.companyName
      }

      // Fill shipper branch address if custom billing address is disabled
      if (!state.input.billingInfo) {
        setShipperAddress(data.billing_address) // Set default billing address
        if (data.business) {
          data.business = null // Reset business data
        }
      }

      // Fill shipper branch address if address is not required
      if (state.input.shipper && state.input.shipper.requiredAddress === false) {
        setShipperAddress(data.shipping.address) // Set default shipping address
        if (data.shipping.business_name) {
          delete data.shipping.business_name // Remove shipping company name
        }
        if (data.shipping.phone) {
          delete data.shipping.phone // Remove shipping phone number
        }
      }

      if (rootGetters['utm/getUtmParams']) {
        data.utm = rootGetters['utm/getUtmParams']
      }
      await hookDispatch(hookDefinitions.CART.PLACE_ORDER.DATA_PROCESS, { actionContext, data })
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/cart/place', data)
      const orderData = await dispatch('UPDATE_DATA_WITH_VARIANTS', response.data)
      commit('SET_PLACED_ORDER', orderData)
      return response
    } catch (e) {
      console.log('PLACE_ORDER error:', e)
      return e.response
    }
  },
}

export { state, actions, mutations, getters }
