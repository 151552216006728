<script>
import BusinessUserMixin from '~/mixins/BusinessUserMixin'
import ProductMixin from '~/sites/shop/mixins/ProductMixin.vue'

export default {
  extends: ProductMixin,
  mixins: [BusinessUserMixin],
  computed: {
    productTags() {
      const userGroups = process.client ? this.$store.getters['user/userGroups'] : []
      const tags = ProductMixin.computed.productTags.apply(this, arguments)

      return tags.filter(tag => {
        const groups = Object.values(tag.groups || {})
        const isUserInRestrictedGroup = userGroups.some(group => !groups.includes(group))
        return !isUserInRestrictedGroup
      })
    },
    quantityDiscount() {
      if (process.client && this.isBusinessUser) {
        return null
      }
      let quantityDiscount = null
      this.product.discounts.forEach(discount => {
        if (discount.isNPlusOne && discount.active) {
          quantityDiscount = discount.name
        }
      })
      return quantityDiscount
    },
  },
}
</script>
