import ProductBox from 'themes/base/components/product/ProductBox'

export default {
  extends: ProductBox,
  props: {
    disableVariants: {
      type: Boolean,
      default: false,
    },
    portrait: {
      type: Boolean,
      default: false,
    },
  },
}
