import ProductAvailability from '~/themes/base/components/product/ProductAvailability'

export default {
  extends: ProductAvailability,
  computed: {
    isPreorder() {
      return this.product.availability.availabilityCode === 'pre-order'
    },
    preorderDate() {
      const date = this.$moment().add(this.product.availability.delayDays, 'days')
      return date.format('MM/YYYY')
    },
    text() {
      let text = ProductAvailability.computed.text.apply(this, arguments)

      if (this.isPreorder) {
        text = this.$t('ProductAvailability.preorder', { date: this.preorderDate })
      }

      return text
    },
  },
}
