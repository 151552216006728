import UniqueSellingPoints from '~/themes/base/components/shop/UniqueSellingPoints'

export default {
  extends: UniqueSellingPoints,
  data() {
    return {
      pointsIcons: {
        'represhop/usp_home': require('@icon/represhop/usp_home.svg?raw'),
        'represhop/usp_branch': require('@icon/represhop/usp_branch.svg?raw'),
        'represhop/usp_delivery': require('@icon/represhop/usp_delivery.svg?raw'),
        'represhop/usp_heart': require('@icon/represhop/usp_heart.svg?raw'),
      },
    }
  },
}
