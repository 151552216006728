/*
  Documentation url:
  https://github.com/heureka/overeno-zakazniky/blob/master/docs/api-documentation-cs.md
*/
export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
    if (context.app.$contentSettings.events.heureka.shopPurchase.enable) {
      const heureka_public_key = context.store.state.globals.settings.integrations.heureka_public_key

      if (heureka_public_key) {
        const response = context.app.$axios.$post('https://api.heureka.cz/shop-certification/v2/order/log', {
          apiKey: heureka_public_key,
          email: order.customer.email,
          orderId: order.code,
          productItemIds: order.items.map(item => item.variantId),
        })
        if (response.code !== 200) {
          console.warn(response)
        }
      }
    }
  })
}
