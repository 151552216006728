import AppImage from '@theme/components/atom/AppImage'
import AppLink from '@theme/components/atom/AppLink'

export default {
  components: {
    AppImage,
    AppLink,
  },
  computed: {
    getUspBlocks() {
      return this.$store.state.globals.uspBlocks || []
    },
  },
}
